@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');
@import '~font-awesome/css/font-awesome.min.css';

html,
body {
    margin: 0;
    font-family: Lato;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    * {
        outline: none;
    }
}

.common-style-tooltip {
    font-weight: 400 !important;
    font-size: 12px !important;
    background-color: var(--strong-text) !important;
    opacity: 80% !important;
    padding: 10px !important;
    position: absolute;
}

.view-assets-tooltip {
    top: -16px !important;
    width: 265px !important;
}

@media screen and (max-width: 600px) {
    .view-assets-tooltip {
        top: -15px !important;
        right: -100px !important;
    }
}

.file-preview-container {
    .mat-dialog-container {
        box-shadow: none;
        background: none;
        padding: 0px !important;
    }
}

.display-none {
    display: none !important;
}